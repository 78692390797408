import React from 'react';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { navigate } from '@reach/router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ROUTES from '../../routes';

import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    media: {
      height: 200,
    },
    imageWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    errorItem: {
      color: colors.noirBlur,
      paddingTop: 10,
    },
  }),
);

export default function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Container style={{ maxWidth: 800, paddingTop: 50, paddingBottom: 50 }}>
        <Typography variant={'h2'}>{t('PAGE_NOT_WORKING')}</Typography>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: 20, paddingBottom: 50 }}
        >
          <Grid item xs={12} sm>
            <Grid item className={classes.imageWrapper}>
              <img
                src={require('../../images/Turtle.jpg')}
                width={'325px'}
                height={'325px'}
              />
            </Grid>
            <Grid item>
              <Typography variant={'body2'} style={{ paddingTop: 20 }}>
                {t('NO_TURTLES')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm>
            <Typography variant={'h3'} className={classes.errorItem}>
              {t('TECHNICAL_ISSUES')}
            </Typography>
            <Typography variant={'body2'} className={classes.errorItem}>
              {t('PLEASE_TRY_AGAIN')}
            </Typography>
            <Typography variant={'body2'} className={classes.errorItem}>
              {t('HOURS_OF_OPERATION')}
            </Typography>
            <Button
              style={{ marginTop: 20 }}
              size="large"
              color="primary"
              variant="contained"
              onClick={() => {
                return navigate(ROUTES.HOME);
              }}
            >
              {t('RETURN_TO_HOME_PAGE')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
